import $ from 'jquery';

$(() => {
    const $openModalBtn = $('.assign-sim');
    const $table = app.DOM.content.find('#sim_details_table');
    const $checkAll = $table.find('#checkall');
    const $checkboxes = $table.find('tbody input[type="checkbox"]');

    $openModalBtn.unbind('click').on('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        const el = $(e.currentTarget);
        if(!el.hasClass('button-green')) {
            return;
        }

        const data = {
            ...el.data(),
            sims: [],
        };

        $.each($checkboxes, (_k, el) => {
            el = $(el);
            if(el.is(':checked')) {
                data.sims.push(el.val());
            }
        });
        app.MODAL_CORE.showModal('assign_sim_details', el, data);
    });

    $checkboxes.unbind('change').on('change', (e) => {
        const el = $(e.currentTarget);
        if($checkAll.is(':checked') && !el.is(':checked')) {
            $checkAll.prop('checked', false);
        }

        let checkedCount = 0;
        $.each($checkboxes, (_k, el) => {
            if($(el).is(':checked')) {
                checkedCount++;
            }
        });

        if($checkboxes.length === checkedCount) {
            $checkAll.prop('checked', true);
        }

        if(checkedCount === 0) {
            $openModalBtn.removeClass('button-green').addClass('button-grey');
        } else {
            $openModalBtn.addClass('button-green').removeClass('button-grey');
        }
    });

    $checkAll.unbind('change').on('change', (e) => {
        let checkedCount = 0;
        $.each($checkboxes, (_k, el) => {
            if($(el).is(':checked')) {
                checkedCount++;
            }
        });

        if($checkboxes.length === checkedCount) {
            $checkboxes.prop('checked', false);
            $checkAll.prop('checked', false);
            $openModalBtn.removeClass('button-green').addClass('button-grey');
            return;
        }

        $checkboxes.prop('checked', true);
        $checkAll.prop('checked', true);
        $openModalBtn.addClass('button-green').removeClass('button-grey');
    });
});